import React, { useState, useEffect } from 'react';
import './style.css';
import incluirQuali from '../JS/IncluirQuali';  // Certifique-se de que o caminho está correto
import incluirAgille from '../JS/incluirAgille'; // Incluindo a nova função para enviar ao Agille
import SuccessModal from '../ModalSucess';

const siglasEstadosDoBrasil = [
  'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA',
  'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
];

const ModalOut = ({ isOpen, onClose, setResultado }) => {
  const [cep, setCep] = useState('');
  const [endereco, setEndereco] = useState('');
  const [bairro, setBairro] = useState('');
  const [numero, setNumero] = useState('');
  const [estado, setEstado] = useState('');
  const [sexo, setSexo] = useState('');
  const [mae, setMae] = useState('');
  const [loading, setLoading] = useState(false);

  // Estado para gerenciar erros
  const [error, setError] = useState(null);

  // Estados para o SuccessModal
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [vendaId, setVendaId] = useState(null);

  // Limpa os campos quando o modal é fechado
  useEffect(() => {
    if (!isOpen) {
      setCep('');
      setEndereco('');
      setBairro('');
      setNumero('');
      setEstado('');
      setSexo('');
      setMae('');
      setError(null); // Limpa o erro quando o modal é fechado
    }
  }, [isOpen]);

  const handleConfirm = async () => {
    if (typeof setResultado === "function") {
      const novoResultado = await new Promise((resolve) => {
        setResultado((prevResultado) => {
          const atualizado = {
            ...prevResultado,
            informacoesobrigatorias: {
              ...prevResultado.informacoesobrigatorias,
              cep,
              endereco,
              bairro,
              numero,
              estado,
              sexo,
              mae,
            },
          };
          resolve(atualizado);
          return atualizado;
        });
      });
  
      console.log("Resultado atualizado após ModalOut:", novoResultado);
  
      setLoading(true); // Ativa o estado de carregamento
      setIsSuccessModalOpen(true); // Abre o SuccessModal imediatamente
  
      try {
        // Envia os dados para o incluirQuali
        const respostaQuali = await incluirQuali(novoResultado);
        console.log('Dados enviados com sucesso para Quali:', respostaQuali);
        
        // Envia os dados para o incluirAgille
        const respostaAgille = await incluirAgille(novoResultado, respostaQuali);
        console.log('Dados enviados com sucesso para Agille:', respostaAgille);
  
        setVendaId(respostaAgille); // Define o vendaId para mostrar no SuccessModal
        setError(null); // Limpa o erro se não houver nenhum problema
      } catch (error) {
        console.error('Erro ao enviar os dados:', error);
      
        let errorMessagesArray = [];
      
        // Verifica se o erro contém o campo detail e se é uma string JSON
        if (error.response?.data?.detail) {
          try {
            // Faz o parsing da string JSON
            const parsedDetail = JSON.parse(error.response.data.detail);
      
            // Verifica se há mensagens no objeto detalhado e extrai os textos
            if (Array.isArray(parsedDetail.messages)) {
              errorMessagesArray = parsedDetail.messages.map(msg => msg.text);
            }
          } catch (parseError) {
            console.error('Erro ao fazer o parsing do detalhe do erro:', parseError);
            errorMessagesArray = [error.response.data.detail];
          }
        } else {
          // Caso não exista um campo detail ou não seja uma string JSON
          errorMessagesArray = [error.message || "Erro desconhecido"];
        }
        
        // Combina as mensagens em uma única string separada por quebras de linha
        const errorMessage = errorMessagesArray.join('\n');
      
        setError(errorMessage); // Define a mensagem de erro no estado
      }
      finally {
        setLoading(false); // Certifique-se de que o estado de carregamento é desativado
      }
    } else {
      console.error("setResultado não é uma função");
    }
  };
  
  
  

  const handleCepChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 8) {
      setCep(value);
    }
  };

  const handleNumeroChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 5) {
      setNumero(value);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Informações de Endereço</h2>

          {/* Campos do formulário */}
          <div className="input-group">
            <label htmlFor="cep">CEP:</label>
            <input
              type="text"
              id="cep"
              value={cep}
              onChange={handleCepChange}
              placeholder="Digite o CEP"
              maxLength="8"
              aria-label="Campo para inserir o CEP"
            />
          </div>

          <div className="input-group">
            <label htmlFor="endereco">Endereço:</label>
            <input
              type="text"
              id="endereco"
              value={endereco}
              onChange={(e) => setEndereco(e.target.value)}
              placeholder="Digite o endereço"
              aria-label="Campo para inserir o endereço"
            />
          </div>

          <div className="input-group">
            <label htmlFor="bairro">Bairro:</label>
            <input
              type="text"
              id="bairro"
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
              placeholder="Digite o bairro"
              aria-label="Campo para inserir o bairro"
            />
          </div>

          <div className="input-group">
            <label htmlFor="numero">Número:</label>
            <input
              type="text"
              id="numero"
              value={numero}
              onChange={handleNumeroChange}
              placeholder="Digite o número"
              maxLength="5"
              aria-label="Campo para inserir o número"
            />
          </div>

          <div className="input-group">
            <label htmlFor="estado">Estado:</label>
            <select
              id="estado"
              value={estado}
              onChange={(e) => setEstado(e.target.value)}
            >
              <option value="">Selecione o estado</option>
              {siglasEstadosDoBrasil.map((sigla) => (
                <option key={sigla} value={sigla}>{sigla}</option>
              ))}
            </select>
          </div>

          <div className="input-group">
            <label htmlFor="sexo">Sexo:</label>
            <select
              id="sexo"
              value={sexo}
              onChange={(e) => setSexo(e.target.value)}
            >
              <option value="">Selecione o sexo</option>
              <option value="M">Masculino</option>
              <option value="F">Feminino</option>
            </select>
          </div>

          <div className="input-group">
            <label htmlFor="mae">Nome da Mãe:</label>
            <input
              type="text"
              id="mae"
              value={mae}
              onChange={(e) => setMae(e.target.value)}
              placeholder="Digite o nome da mãe"
              aria-label="Campo para inserir o nome da mãe"
            />
          </div>

          <div className="modal-actions">
            <button className="btn-cancel" onClick={onClose} disabled={loading}>
              Cancelar
            </button>
            <button className="btn-confirm" onClick={handleConfirm} disabled={loading}>
              {loading ? 'Enviando...' : 'Confirmar'}
            </button>
          </div>

          {/* Modal de carregamento */}
          {loading && (
            <div className="loading-overlay">
              <div className="loading-spinner"></div>
              <p>Enviando dados, por favor, aguarde...</p>
            </div>
          )}
        </div>
      </div>

      {/* SuccessModal */}
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        vendaId={vendaId}
        loading={loading}
        error={error}
      />
    </>
  );
};

export default ModalOut;
