// bancosPermitidos.js
export const bancosPermitidosDestino = [
    "1002 - Total Cash",
    "12 - Banco Inbursa S.A",
    "925 - BRB Cred Financ E Invest S.A",
    "341 - Itaú Unibanco S.A",
    "935 - FACTA",
    "954 - Banco Digio SA",
    "1004 - Quali Banking",
  ];
  