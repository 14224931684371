import React, { useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';

const SuccessModal = ({ isOpen, onClose, vendaId, loading, error }) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (loading) {
      setMessage('Processando, por favor aguarde...');
    } else if (error) {
      setMessage(`Erro ao processar:\n${error}`);
    } else if (vendaId) {
      setMessage('Proposta digitada e inclusa com sucesso');
    }
  }, [loading, error, vendaId]);

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">Resultado</Dialog.Title>
          <Dialog.Description style={{ whiteSpace: 'pre-line' }}>
            {message || 'Processando...'}
          </Dialog.Description>
          <Dialog.Close asChild>
            <button className="CloseButton" onClick={onClose}>Fechar</button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SuccessModal;
