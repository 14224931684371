// js/contasimples.js

export const subtrair = (a, b) => a - b;

export const aumentoSalario = (valorParcela, novoResultado, prazo) => {
  return (valorParcela - novoResultado) * prazo;
};

export const multiplicar = (valorPresente, prazo) => {
  return valorPresente * prazo;
};
