import axios from "axios";

export const listarBancos = async () => {
  const url = "https://agiluscrm.com.br:40000/api/Banco/Listar";
  const token = "nywkYBg-XrK8a93Clo8xtvbFmRGp5ocUqwYJuSV1oqp1oyyGGu8D81bgP9fh2a0c16QCAFUWTrJnF4958BCzg5QpwHFxVftETUSNBOk4E3K0Hs7A4_x9XokJOn24jm7ND4j_U-JrC6nhBF0syvaDImumpHSAswAvBi1wgd7rokkbRE-zTBkf_siaQeILFnamHtGtAOKXXs6_qOGYirR8FPz6emLnHPWf6UrTJRt_B3owFaPEWApvhB5UEvGyWaScB3FqzdXc8StLXBAzZonheDAxJQhWhPa_pts8Bmdjppr_byEiuvABB4mUl6jJiRS9LGQlatbQGanlFmMcwq5wbK5Vf4u_zykDf17PvWfWPC8fcUXKpU7VPhSUcmZ1NOjhHiVV8CDID2O5JUJlt0UJQNb6EoeinUSbDoITU7tCLMRZ_DfrZfWHuw78skhs64mA"; // Substitua pelo token correto

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar os bancos:", error);
    return [];
  }
};
