import axios from "axios";
import { axiosInstance } from "./axios";

const ipList = [
  'https://portal.agillecred.com.br/api/'
];

export async function connectToApi() {
  for (let i = 0; i < ipList.length; i++) {
    try {
      const response = await axios.get(`${ipList[i]}`);
      localStorage.setItem("apiUrl", `${ipList[i]}`);
      axiosInstance.defaults.baseURL = ipList[i];
      break;
    } catch (error) {
      console.log(`Não foi possível se conectar com um dos IPs`);
    }
  }
}
