import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../pages/Navbar";
import PortabilidadePura from "./PortabilidadePura";
import PortRefin from "./PortRefin";
// import { toPng } from "html-to-image";
//import html2canvas from "html2canvas";
import { listarBancos } from "./js/listarBancos";
import { bancosPermitidosDestino } from "./js/bancosPermitidos";
import {
  calcularTaxa,
  calcularSimulacao,
  calcularValorPresente,
  calcularValorOperacao,
} from "./js/formulas";
import coeficientes from "./data/coeficientes.json"; // Importa os coeficientes do JSON
import { aumentoSalario, multiplicar, subtrair } from "./js/contasimples";
import "./style.css";
import portPuraBackground from "../../../img/portpura.png"; 
import portRefinBackground from "../../../img/portrefin.png"

const GProposta = () => {
  const [prazo, setPrazo] = useState("");
  const [valorParcela, setValorParcela] = useState("");
  const [saldoDevedor, setSaldoDevedor] = useState("");
  const [taxaSimulation, setTaxaSimulation] = useState("");
  const [resultado, setResultado] = useState("N/A");
  const [novoResultado, setNovoResultado] = useState("N/A");
  const [valorPresente, setValorPresente] = useState("N/A");
  const [diferencaVP, setDiferencaVP] = useState("N/A");
  const [aumentoSalarioResultado, setAumentoSalarioResultado] = useState("N/A");
  const [multiplicacaoResultado, setMultiplicacaoResultado] = useState("N/A");
  const [subtracaoReducaoParcela, setSubtracaoReducaoParcela] = useState("N/A");
  const [valorOperacao, setValorOperacao] = useState("N/A");
  const [valorOperacaoSaldo, setValorOperacaoSaldo] = useState("N/A");
  const [bancos, setBancos] = useState([]);
  const [bancoSelecionado, setBancoSelecionado] = useState("");
  const [bancoDestino, setBancoDestino] = useState("");
  const [dataAtual, setDataAtual] = useState("");
  const portPuraRef = useRef(null);
  const portRefinRef = useRef(null);

  // Atualizar a data atual
  useEffect(() => {
    const hoje = new Date();
    const dia = String(hoje.getDate()).padStart(2, "0");
    const mes = String(hoje.getMonth() + 1).padStart(2, "0"); // Janeiro é 0
    const ano = hoje.getFullYear();
    setDataAtual(`${dia}/${mes}/${ano}`);
  }, []);

  // Chamada para listar os bancos
  useEffect(() => {
    const fetchBancos = async () => {
      const bancosListados = await listarBancos();
      console.log("Bancos retornados da API:", bancosListados);
      setBancos(bancosListados);
    };

    fetchBancos();
  }, []);


    // Função para capturar imagem da seção "Port Pura"
    const handleGenerateImage = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
    
      // Carregar a imagem de fundo
      const background = new Image();
      background.src = portPuraBackground; // Certifique-se do caminho correto
    
      background.onload = () => {
        // Configurar o tamanho do canvas para corresponder à imagem
        canvas.width = background.width;
        canvas.height = background.height;
    
        // Desenhar a imagem de fundo no canvas
        ctx.drawImage(background, 0, 0, canvas.width, canvas.height);
    
        // Configurar estilo do texto
        ctx.font = "bold 24px Arial"; // Fonte maior para maior visibilidade
        ctx.textAlign = "center"; // Centraliza os textos
        ctx.fillStyle = "#000"; // Cor padrão preta
    
        // Renderizar textos
        ctx.fillStyle = "#000"; // Cor do texto
        ctx.fillText(`${dataAtual}`, canvas.width / 5, 260); // Ajuste x, y
    
        ctx.textAlign = "left";
        ctx.fillStyle = "#000";
        ctx.fillText(`Banco Originador: ${bancoSelecionado.split(' - ')[1]}`, 450, 235); // Ajuste x, y
        ctx.fillText(`Banco Destino: ${bancoDestino.split(' - ')[1]}`, 450, 263); // Ajuste x, y
    
        // Taxa Atual e Reduzida
        ctx.fillStyle = "#ff0000";
        ctx.font = "bold 32px Arial";
        ctx.fillText(`${resultado}`, 280, 590); // Ajuste x, y
        const resultadoTextWidth = ctx.measureText(`${resultado}`).width;
        ctx.beginPath();
        ctx.moveTo(285, 577); // Ajuste y para ficar no meio do texto
        ctx.lineTo(285 + resultadoTextWidth, 577);
        ctx.strokeStyle = "#ff0000";
        ctx.lineWidth = 2;
        ctx.stroke();

        
        ctx.fillStyle = "#00b300";
        ctx.font = "bold 36px Arial";
        ctx.fillText(`${taxaSimulation ? `${parseFloat(taxaSimulation).toFixed(2)}%` : "N/A"}`, 720, 593); // Ajuste x, y
    
        // Parcela Atual e Reduzida
        ctx.fillStyle = "#ff0000";
        ctx.fillText(`${valorParcela ? `R$${valorParcela}` : "N/A"}`, 263, 805); // Ajuste x, y
        // Calcular o tamanho do texto para desenhar o risco
        const textWidth = ctx.measureText(`R$${valorParcela}`).width;
        const startX = 263; // Posição x inicial do texto
        const startY = 805; // Posição y do texto

        // Desenhar uma linha horizontal sobre o texto para simular o risco
        ctx.beginPath();
        ctx.moveTo(startX, startY - 10); // Ajuste a posição vertical (-10) para ficar no meio do texto
        ctx.lineTo(startX + textWidth, startY - 10); // Tamanho da linha com base na largura do texto
        ctx.strokeStyle = "#ff0000"; // Cor da linha, igual ao texto
        ctx.lineWidth = 2; // Espessura da linha
        ctx.stroke();

        ctx.fillStyle = "#00b300";
        ctx.fillText(`${novoResultado ? `R$${novoResultado}` : "N/A"}`, 683, 800); // Ajuste x, y
    
        // Aumento Salário e Economia Total
        ctx.fillStyle = "#00b300";
        ctx.font = "bold 52px Arial";
        ctx.fillText(`${subtracaoReducaoParcela ? `R$${subtracaoReducaoParcela}` : "N/A"}`, 195, 990); // Ajuste x, y
        const formattedMultiplicacaoResultado = multiplicacaoResultado !== "N/A" ? parseFloat(multiplicacaoResultado).toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "N/A";
        ctx.fillText(`${formattedMultiplicacaoResultado ? `R$${formattedMultiplicacaoResultado}` : "N/A"}`, 630, 990); // Ajuste x, y
        // Baixar a imagem gerada
        const link = document.createElement("a");
        link.download = "portabilidade-pura.png";
        link.href = canvas.toDataURL("image/png");
        link.click();
        canvas.toBlob((blob) => {
          if (blob) {
            const item = new ClipboardItem({ "image/png": blob });
            navigator.clipboard.write([item]).then(() => {
              console.log("Imagem copiada para a área de transferência");
            }).catch((error) => {
              console.error("Erro ao copiar a imagem para a área de transferência", error);
            });
          }
        });
      };
    };

    // port + refin 
    const handleGeneratePortRefinImage = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Carregar a imagem de fundo para Port + Refin
      const background = new Image();
      background.src = portRefinBackground; // Certifique-se do caminho correto

      background.onload = () => {
        // Configurar o tamanho do canvas para corresponder à imagem
        canvas.width = background.width;
        canvas.height = background.height;

        // Desenhar a imagem de fundo no canvas
        ctx.drawImage(background, 0, 0, canvas.width, canvas.height);

        // Configurar estilo do texto
        ctx.font = "bold 24px Arial"; // Fonte maior para maior visibilidade
        ctx.textAlign = "center"; // Centraliza os textos
        ctx.fillStyle = "#000"; // Cor padrão preta

        // Renderizar textos
        ctx.fillStyle = "#000"; // Cor do texto
        ctx.font = "bold 32px Arial";
        ctx.fillText(`${dataAtual}`, canvas.width / 5, 283); // Ajuste x, y

        ctx.textAlign = "left";
        ctx.fillStyle = "#000";
        ctx.font = "bold 25px Arial";
        ctx.fillText(
          `Banco Originador: ${bancoSelecionado.split(" - ")[1]}`,
          450,
          262
        ); // Ajuste x, y
        ctx.fillText(
          `Banco Destino: ${bancoDestino.split(" - ")[1]}`,
          450,
          283
        ); // Ajuste x, y

        ctx.fillStyle = "#00b300";
        ctx.font = "bold 36px Arial";
        ctx.fillText(`${valorParcela ? `R$${valorParcela}` : "N/A"}`, 817, 690); // Ajuste x, y

        ctx.fillStyle = "#000";
        ctx.font = "bold 32px Arial";
        ctx.fillText(`${valorParcela ? `R$${valorParcela}` : "N/A"}`, 175, 692); // Ajuste x, y

        // Valor Troco
        ctx.fillStyle = "#00b300";
        ctx.font = "bold 52px Arial"; // Aumente de "24px" para "32px" ou qualquer outro valor desejado
        const formattedValorOperacaoSaldo = valorOperacaoSaldo !== "N/A" ? parseFloat(valorOperacaoSaldo).toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "N/A";
        ctx.fillText(`${formattedValorOperacaoSaldo ? `R$${formattedValorOperacaoSaldo}` : "N/A"}`, 415, 905);
        

        // Baixar a imagem gerada
        const link = document.createElement("a");
        link.download = "port_refin.png";
        link.href = canvas.toDataURL("image/png");
        link.click();
        // Copiar a imagem para a área de transferência
        canvas.toBlob((blob) => {
          if (blob) {
            const item = new ClipboardItem({ "image/png": blob });
            navigator.clipboard
              .write([item])
              .then(() => {
                console.log("Imagem copiada para a área de transferência");
              })
              .catch((error) => {
                console.error(
                  "Erro ao copiar a imagem para a área de transferência",
                  error
                );
              });
          }
        });
      };
    };

  useEffect(() => {
    console.log("Valores para cálculo da taxa:", {
      prazo,
      valorParcela,
      saldoDevedor,
    });
    if (prazo && valorParcela && saldoDevedor) {
      const resultadoCalc = calcularTaxa(
        prazo,
        -Math.abs(valorParcela),
        saldoDevedor
      );
      console.log("Resultado calculado da taxa:", resultadoCalc);
      setResultado(
        resultadoCalc ? (resultadoCalc * 100).toFixed(2) + " %" : "N/A"
      );
    } else {
      setResultado("N/A");
    }
  }, [prazo, valorParcela, saldoDevedor]);

  useEffect(() => {
    if (prazo && saldoDevedor && taxaSimulation) {
      const novoResultadoCalc = calcularSimulacao(
        taxaSimulation,
        prazo,
        saldoDevedor
      );
      console.log("Resultado calculado da simulação:", novoResultadoCalc);
      setNovoResultado(
        novoResultadoCalc ? novoResultadoCalc.toFixed(2) : "N/A"
      );
    } else {
      setNovoResultado("N/A");
    }
  }, [prazo, saldoDevedor, taxaSimulation]);

  useEffect(() => {
    if (prazo && valorParcela && taxaSimulation) {
      const vpCalc = calcularValorPresente(taxaSimulation, prazo, valorParcela);
      console.log("Valor Presente calculado:", vpCalc);
      setValorPresente(vpCalc ? vpCalc.toFixed(2) : "N/A");
    } else {
      setValorPresente("N/A");
    }
  }, [prazo, valorParcela, taxaSimulation]);

  useEffect(() => {
    if (valorPresente !== "N/A" && saldoDevedor !== "N/A") {
      const diferenca = Math.abs(
        parseFloat(valorPresente) - parseFloat(saldoDevedor)
      );
      console.log("Diferença VP calculada:", diferenca);
      setDiferencaVP(diferenca.toFixed(2));
    } else {
      setDiferencaVP("N/A");
    }
  }, [valorPresente, saldoDevedor]);

  useEffect(() => {
    if (valorParcela && novoResultado !== "N/A" && prazo) {
      const aumento = aumentoSalario(
        parseFloat(valorParcela),
        parseFloat(novoResultado),
        parseFloat(prazo)
      );
      console.log("Aumento Salário calculado:", aumento);
      setAumentoSalarioResultado(aumento.toFixed(2));
    } else {
      setAumentoSalarioResultado("N/A");
    }
  }, [valorParcela, novoResultado, prazo]);

  useEffect(() => {
    if (subtracaoReducaoParcela !== "N/A" && prazo) {
      const multiplicacao = multiplicar(
        parseFloat(subtracaoReducaoParcela),
        parseFloat(prazo)
      );
      console.log("Multiplicação calculada:", multiplicacao);
      setMultiplicacaoResultado(multiplicacao.toFixed(2));
    } else {
      setMultiplicacaoResultado("N/A");
    }
  }, [subtracaoReducaoParcela, prazo]);

  // Aumento Salario
  useEffect(() => {
    if (novoResultado !== "N/A" && valorParcela) {
      const subtracao = subtrair(
        parseFloat(valorParcela),
        parseFloat(novoResultado)
      );
      console.log("Subtração Redução - Valor Parcela:", subtracao);
      setSubtracaoReducaoParcela(subtracao.toFixed(2));
    } else {
      setSubtracaoReducaoParcela("N/A");
    }
  }, [novoResultado, valorParcela]);

  // Cálculo do Valor Operação
  useEffect(() => {
    if (bancoDestino && valorParcela) {
      // Busca o coeficiente associado ao banco destino no JSON
      const bancoSelecionadoCoeficiente = coeficientes.coeficientes.find(
        (item) => `${item.codigoBanco} - ${item.nomeBanco}` === bancoDestino
      );
      if (bancoSelecionadoCoeficiente) {
        const coeficiente = bancoSelecionadoCoeficiente.coeficiente;
        console.log("Coeficiente selecionado:", coeficiente);

        // Calcula o valor da operação
        const valorOperacaoCalc = calcularValorOperacao(
          coeficiente,
          valorParcela
        );
        console.log("Resultado Valor Operação:", valorOperacaoCalc);
        setValorOperacao(
          valorOperacaoCalc ? valorOperacaoCalc.toFixed(2) : "N/A"
        );
      } else {
        console.warn(
          "Coeficiente não encontrado para o banco destino:",
          bancoDestino
        );
        setValorOperacao("N/A");
      }
    } else {
      setValorOperacao("N/A");
    }
  }, [bancoDestino, valorParcela]);

  // Cálculo do Valor Operação e Valor Operação - Saldo
  useEffect(() => {
    if (bancoDestino && valorParcela) {
      const bancoSelecionadoCoeficiente = coeficientes.coeficientes.find(
        (item) => `${item.codigoBanco} - ${item.nomeBanco}` === bancoDestino
      );
      if (bancoSelecionadoCoeficiente) {
        const coeficiente = bancoSelecionadoCoeficiente.coeficiente;
        console.log("Coeficiente selecionado:", coeficiente);

        // Calcula o valor da operação
        const valorOperacaoCalc = calcularValorOperacao(
          coeficiente,
          valorParcela
        );
        console.log("Resultado Valor Operação:", valorOperacaoCalc);
        setValorOperacao(
          valorOperacaoCalc ? valorOperacaoCalc.toFixed(2) : "N/A"
        );

        // Calcula Valor Operação - Saldo Devedor
        if (valorOperacaoCalc && saldoDevedor) {
          const valorSaldo = subtrair(valorOperacaoCalc, saldoDevedor);
          console.log("Resultado Valor Operação - Saldo:", valorSaldo);
          setValorOperacaoSaldo(valorSaldo.toFixed(2));
        } else {
          setValorOperacaoSaldo("N/A");
        }
      } else {
        console.warn(
          "Coeficiente não encontrado para o banco destino:",
          bancoDestino
        );
        setValorOperacao("N/A");
        setValorOperacaoSaldo("N/A");
      }
    } else {
      setValorOperacao("N/A");
      setValorOperacaoSaldo("N/A");
    }
  }, [bancoDestino, valorParcela, saldoDevedor]);

  const handleBancoChange = (e) => {
    setBancoSelecionado(e.target.value);
  };

  const handleBancoDestinoChange = (e) => {
    setBancoDestino(e.target.value);
  };

  return (
    <>
      <Navbar />
      <section className="gproposta-container">
        <div className="conteudo-gproposta">
          <h2>Simulação</h2>
          <p className="data-atual">Data Atual: {dataAtual}</p>{" "}
          {/* Exibe a data atual */}
          <div className="inputs-section">
            <div className="input-group">
              <label>Prazo</label>
              <input
                type="number"
                placeholder="Prazo"
                value={prazo}
                onChange={(e) => setPrazo(Number(e.target.value) || "")}
              />
            </div>
            <div className="input-group">
              <label>Valor da Parcela</label>
              <input
                type="number"
                placeholder="Valor da Parcela"
                value={valorParcela}
                onChange={(e) => setValorParcela(Number(e.target.value) || "")}
              />
            </div>
            <div className="input-group">
              <label>Saldo Devedor</label>
              <input
                type="number"
                placeholder="Saldo Devedor"
                value={saldoDevedor}
                onChange={(e) => setSaldoDevedor(Number(e.target.value) || "")}
              />
            </div>
            <div className="input-group">
              <label>Taxa de Simulação</label>
              <input
                type="number"
                placeholder="Taxa de Simulação"
                value={taxaSimulation}
                onChange={(e) =>
                  setTaxaSimulation(Number(e.target.value) || "")
                }
              />
            </div>

            <div className="input-group">
              <label>Banco Originador</label>
              <input
                type="text"
                placeholder="Digite o Código ou Nome do Banco"
                list="bancos"
                value={bancoSelecionado}
                onChange={handleBancoChange}
              />
              <datalist id="bancos">
                {bancos.map((banco, index) => (
                  <option
                    key={`${banco.CodigoBanco}-${index}`}
                    value={`${banco.CodigoBanco} - ${banco.Nome}`}
                  >
                    {banco.CodigoBanco} - {banco.Nome}
                  </option>
                ))}
              </datalist>
            </div>

            <div className="input-group">
              <label>Banco Destino</label>
              <input
                type="text"
                placeholder="Digite o Código ou Nome do Banco"
                list="bancosDestino"
                value={bancoDestino}
                onChange={handleBancoDestinoChange}
              />
              <datalist id="bancosDestino">
                {bancosPermitidosDestino.map((banco, index) => (
                  <option key={`${banco}-${index}`} value={banco}>
                    {banco}
                  </option>
                ))}
              </datalist>
            </div>
          </div>
          <p className="taxa-atual">Taxa Atual: {resultado}</p>
          <hr />
          <div className="resultado-section">
            <div className="resultado-container">
              <h3>Port Pura</h3>
              <div ref={portPuraRef}>
                <PortabilidadePura
                  dataInclusao={dataAtual}
                  bancoOriginador={bancoSelecionado}
                  bancoDestino={bancoDestino}
                  taxaAtual={resultado}
                  taxaReduzida={taxaSimulation ? `${parseFloat(taxaSimulation).toFixed(2)}` : "N/A"}
                  parcelaAtual={valorParcela ? `R$${valorParcela}` : "N/A"}
                  parcelaReduzida={novoResultado ? `R$${novoResultado}` : "N/A"}
                  aumentoSalario={
                    subtracaoReducaoParcela ? `R$${subtracaoReducaoParcela}` : "N/A"
                  }
                  economiaTotal={
                    multiplicacaoResultado ? `R$${multiplicacaoResultado}` : "N/A"
                  }
                />
              </div>

              <button
                className="btn-gerar-imagem"
                onClick={handleGenerateImage}
              >
                Gerar Imagem
              </button>
            </div>
          </div>
          <div className="resultado-container">
            <h3>Port + Refin</h3>
            <div ref={portRefinRef}>
              <PortRefin
                dataInclusao={dataAtual}
                bancoOriginador={bancoSelecionado}
                bancoDestino={bancoDestino}
                valorParcela={valorParcela ? `R$${valorParcela}` : "N/A"}
                valorTroco={valorOperacaoSaldo ? `R$${valorOperacaoSaldo}` : "N/A"}
              />
            </div>

            <button
              className="btn-gerar-imagem"
              onClick={handleGeneratePortRefinImage}
            >
              Gerar Imagem 
            </button>

          </div>
        </div>
      </section>
    </>
  );
};

export default GProposta;
