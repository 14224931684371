import React, { useState, useEffect, useCallback } from 'react';
import './style.css';

const ModalComplemento = ({ isOpen, onClose, onConfirm, setResultado, prevResultado }) => {
  const [ddd, setDdd] = useState('');
  const [telefone, setTelefone] = useState('');
  const [rg, setRg] = useState('');
  const [email, setEmail] = useState('');
  const [dtEmissao, setEmissao] = useState('');
  const [orgaoExpedidor, setExpedidor] = useState('SSP/SP');

  const handleDddChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 2) setDdd(value);
  };

  const handleTelefoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 9) setTelefone(value);
  };

  const handleRgChange = (e) => {
    const value = e.target.value;
    if (value.length <= 11) setRg(value);
  };

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleEmissaoChange = (e) => setEmissao(e.target.value);
  const handleExpedidorChange = (e) => setExpedidor(e.target.value);

  const resetFields = useCallback(() => {
    setDdd('');
    setTelefone('');
    setRg('');
    setEmail('');
    setEmissao('');
    setExpedidor('SSP/SP');
  }, []);

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const handleConfirm = () => {
    // Verifica se o campo de telefone tem exatamente 9 dígitos
    if (telefone.length !== 9) {
      alert('O campo de telefone precisa ter exatamente 9 dígitos.');
      return;
    }

    // Verifica se o campo de RG tem mais de 9 dígitos
    if (rg.length <= 4) {
      alert('O campo de RG precisa ter mais de 4 dígitos.');
      return;
    }

    const currentDdd = ddd || prevResultado?.informacoesobrigatorias?.ddd || '';
    const currentTelefone = telefone || prevResultado?.informacoesobrigatorias?.telefone || '';
    const currentRg = rg || prevResultado?.informacoesobrigatorias?.rg || '';
    const currentEmail = email || prevResultado?.informacoesobrigatorias?.email || '';
    const currentDtEmissao = dtEmissao || prevResultado?.informacoesobrigatorias?.dtEmissao || '';
    const currentOrgaoExpedidor = orgaoExpedidor || prevResultado?.informacoesobrigatorias?.orgaoExpedidor || 'SSP/SP';

    if (typeof setResultado === 'function') {
      setResultado((prevResultado) => ({
        ...prevResultado,
        informacoesobrigatorias: {
          ...prevResultado.informacoesobrigatorias,
          ddd: currentDdd,
          telefone: currentTelefone,
          rg: currentRg,
          email: currentEmail,
          dtEmissao: currentDtEmissao,
          orgaoExpedidor: currentOrgaoExpedidor,
        },
      }));
    }

    onConfirm({ ddd: currentDdd, telefone: currentTelefone });
    handleClose();
  };

  useEffect(() => {
    if (isOpen) {
      setDdd(prevResultado?.informacoesobrigatorias?.ddd || '');
      setTelefone(prevResultado?.informacoesobrigatorias?.telefone || '');
      setRg(prevResultado?.informacoesobrigatorias?.rg || '');
      setEmail(prevResultado?.informacoesobrigatorias?.email || '');
      setEmissao(prevResultado?.informacoesobrigatorias?.dtEmissao || '');
      setExpedidor(prevResultado?.informacoesobrigatorias?.orgaoExpedidor || 'SSP/SP');
    }
  }, [isOpen, prevResultado]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Informações de Contato</h2>

        <div className="input-group">
          <label htmlFor="ddd">DDD:</label>
          <input
            type="text"
            id="ddd"
            value={ddd}
            onChange={handleDddChange}
            placeholder="Digite o DDD"
            maxLength="2"
            aria-label="Campo para inserir o código DDD"
          />
        </div>

        <div className="input-group">
          <label htmlFor="telefone">Telefone:</label>
          <input
            type="text"
            id="telefone"
            value={telefone}
            onChange={handleTelefoneChange}
            placeholder="Digite o telefone"
            maxLength="9"
            aria-label="Campo para inserir o número de telefone"
          />
        </div>

        <div className="input-group">
          <label htmlFor="rg">RG:</label>
          <input
            type="text"
            id="rg"
            value={rg}
            onChange={handleRgChange}
            placeholder="Digite o RG"
            maxLength="11"
          />
        </div>

        <div className="input-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Digite o email"
          />
        </div>

        <div className="input-group">
          <label htmlFor="Emissao">Data Emissão:</label>
          <input
            type="date"
            id="Emissao"
            value={dtEmissao}
            onChange={handleEmissaoChange}
            placeholder="Digite a Data de Emissão"
          />
        </div>

        <div className="input-group">
          <label htmlFor="expedidor">Órgão Expedidor:</label>
          <select
            id="expedidor"
            value={orgaoExpedidor}
            onChange={handleExpedidorChange}
          >
            <option value="SSP/SP">SSP/SP</option>
            <option value="IFP">IFP</option>
            <option value="IIPM">IIPM</option>
            <option value="DPT">DPT</option>
          </select>
        </div>

        <div className="modal-actions">
          <button className="btn-cancel" onClick={handleClose}>
            Cancelar
          </button>
          <button className="btn-confirm" onClick={handleConfirm}>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalComplemento;
