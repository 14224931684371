import axios from 'axios';

// Função para alterar a fase
const alterarFase = async (entidadeId, faseDestinoId, motivoFaseId = '', camposSolicitados = {}, textoOcorrencia = '') => {
  try {
    // URL com os parâmetros passados
    const url = `https://agiluscrm.com.br:40000/api/TrocaFase/Alterar?entidadeId=${entidadeId}&faseDestinoId=${faseDestinoId}&motivoFaseId=${motivoFaseId}&camposSolicitados=${encodeURIComponent(JSON.stringify(camposSolicitados))}&textoOcorrencia=${encodeURIComponent(textoOcorrencia)}`;

    // Configuração do header com Bearer token
    const config = {
      headers: {
        Authorization: `Bearer A6pWwCwciYOXbR7Se8DtIn3ezAv_Mzh68EtPU3Ib5tnP6UuOt6ZXK2Vf-V-gHsRKgvzR-yGXQsWuAs4UUnzOnruDuLhntqWfHAUXyA6r11j9ztgiYN3l4O9j3IDnH6ByMGbqOftSPzYGutF8ggAYkORDhrd8RmRDwnadqoG7gcpe1qz5Zhva_K-jzXKcIygkxwLC5aH3isA8Xtl6o_70EHSK5KPN6dUgoUrNf8Vt-AuRiuIv4lYjfBVe_wOwvxnoMbGNrp7vOARFCL2ANYeaSikFc2bTMmSSreDiYvvxiK7NObzYN5TcnwW-rcUe2GOarYztTkzaACHkUs_ssfPACeYjnI2UR1iF8XoofNi5b_1_Pukzx3D6pqROBSsxGWVg5RWoe-c7LZS4NVj946Huk-hUPoxdA56R7jJXRH_ia2lGTLGzu63TcsWY-qxs6syX`, 
        'Content-Type': 'application/json',
      },
    };

    // Fazendo o PUT request
    const response = await axios.put(url, {}, config);

    // Retorna a resposta da API
    return response.data;
  } catch (error) {
    console.error('Erro ao alterar a fase:', error.response?.data || error.message);
    throw error;
  }
};

export default alterarFase;
