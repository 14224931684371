import React, { forwardRef } from "react";
import "./PortabilidadePura.css";

const PortabilidadePura = forwardRef(({
  dataInclusao,
  bancoOriginador,
  bancoDestino,
  taxaAtual,
  taxaReduzida,
  parcelaAtual,
  parcelaReduzida,
  aumentoSalario,
  economiaTotal,
}, ref) => {
  return (
    <div className="portabilidade-pura-container" ref={ref}>
      {/* Header com informações gerais */}
      {/* <div className="header">
        <p>Data Inclusão: {dataInclusao}</p>
        <p>
          Banco <strong>Originador:</strong> {bancoOriginador}
        </p>
        <p>
          Banco <strong>Destino:</strong> {bancoDestino}
        </p>
      </div> */}

      {/* Título da seção */}
      

      {/* Seção de Taxas */}
      <div className="section">
        <div className="item text-taxa-atual">
          <strong>Atual</strong>
          <p>{taxaAtual}</p>
        </div>
        <div className="item text-taxa-reduzida">
          <strong>Reduzida</strong>
          <p>{taxaReduzida}</p>
        </div>
      </div>

      {/* Seção de Parcelas */}
      <div className="section">
        <div className="item text-parcela-atual">
          <strong>Atual</strong>
          <p>{parcelaAtual}</p>
        </div>
        <div className="item text-parcela-reduzida">
          <strong>Reduzida</strong>
          <p>{parcelaReduzida}</p>
        </div>
      </div>

      {/* Seção de Resultados */}
      <div className="section">
        <div className="item text-aumento-salario">
          <strong>Aumento Salário</strong>
          <p>{aumentoSalario}</p>
        </div>
        <div className="item text-economia-total">
          <strong>Economia Total</strong>
          <p>{economiaTotal}</p>
        </div>
      </div>
    </div>
  );
});

export default PortabilidadePura;