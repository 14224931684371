import { React, useState, useEffect } from "react";
import { extratoGET } from "../../../api/api-portal";
import "./style.css";
import {
  ContainerFullExtrato,
  ContainerID,
  ContainerMC,
  ContainerIP,
  ContainerCE,
  ContainerRMC,
  ContainerRCC,
  IdFiliado,
  LinhaA,
  LinhaBA,
  LinhaB,
  LinhaC,
  LinhaD,
  Nome,
  NB,
  CPF,
  NomeMae,
  RG,
  Endereco,
  Bairro,
  Cidade,
  ContainerDB,
  DadosBeneficio,
  Especie,
  Situacao,
  Caracter,
  RepLegal,
  Pensao,
  BloqEmprestimo,
  Elegivel,
  MargemConsig,
  BaseCalc,
  MargemEmp,
  MargemRMC,
  MargemIRMC,
  BancoIP,
  Tipo,
  Ag,
  Cc,
  ContratoEmprestimos,
  Ajuste,
  Emprestimo,
  Banco,
  PrimeiraParcela,
  UltimaParcela,
  SituacaoContratos,
  ValorEmprestado,
  ValorParcela,
  DataInclusao,
  Parcelas,
  TitleRMC,
  NumContrato,
  BancoRCC,
  DataInclusaoRMC,
  SituacaoRCC,
  LimitRMC,
  TitleRCC,
  Limite,
  DataInclusao2,
  Center,
} from "./Container";
import Navbar from "../../pages/Navbar"
import { useParams } from "react-router-dom";

export default function ConsultaExt() {
  const { cpf } = useParams();
  const [value, setValue] = useState("");
  const [extrato, setExtrato] = useState();
  const [carregar, setCarregar] = useState(false);
  const [itemAtivo, setItemAtivo] = useState(0);
  let converter = new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL'
  })

  async function Consultar() {
    setCarregar(true);
    setExtrato(await extratoGET(value ? value : cpf));
    setCarregar(false);
  }

  function handleClick() {
    Consultar();
  }

  useEffect(() => {
    document.title = "Consulta de Extrato";
    if (cpf != null) {
      setValue(cpf);
      setTimeout(() => {
        Consultar();
      }, 500);
    }
  }, []);

  const handleItemAtivo = (e) => {
    setItemAtivo(e.target.value);
  };

  return (
    <>
    <Navbar carregar={carregar} /> {/* Integrando o Navbar */}
      <section className="searchContainer2">
        <h1>CONSULTA EXTRATO</h1>
        <div className="container-input-extrato2">
          <input
            autoFocus
            placeholder="CPF"
            className="searchBox2"
            type="search"
            maxLength={14} // Atualizado para acomodar os pontos e traço do CPF
            value={value}
            onChange={(e) => {
              const value = e.target.value;
              let formattedValue = value.replace(/\D/g, ""); // Remove todos os caracteres não-numéricos

              if (formattedValue.length === 10) {
                // Se o comprimento da string formatada for igual a 10, remova todas as pontuações
                formattedValue = formattedValue.replace(/[^\d]/g, "");
              } else if (formattedValue.length <= 11) {
                // CPF tem 11 dígitos
                formattedValue = formattedValue
                  .replace(/(\d{3})(\d)/, "$1.$2")
                  .replace(/(\d{3})(\d)/, "$1.$2")
                  .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
              } else if (formattedValue.length <= 10) {
                // Matrícula tem 10 dígitos
                formattedValue = formattedValue.replace(/(\d{5})(\d)/, "$1-$2");
              }

              setValue(formattedValue);
            }}
            disabled={carregar}
          />
        </div>
        <div className="container-btn-extrato2">
          <button onClick={handleClick}>Pesquisar</button>
        </div>

        {extrato ? (
          <>
            <select onChange={handleItemAtivo}>
              {extrato.map((item, index) => (
                <option key={index} value={index}>
                  {item.benefitNumber}
                </option>
              ))}
            </select>

            <ContainerFullExtrato>
              <ContainerID>
                <IdFiliado>Identificação do Filiado</IdFiliado>
                <LinhaA>
                  <Nome>
                    <span>Nome:&ensp;</span>
                    {extrato[itemAtivo].name}
                  </Nome>
                  <NB>
                    <span>NB:&ensp;</span> {extrato[itemAtivo].benefitNumber}
                  </NB>
                  <CPF>
                    <span>CPF:&ensp;</span>
                    {extrato[itemAtivo].documentNumber}
                  </CPF>
                </LinhaA>
                <LinhaB>
                  <NomeMae>
                    <span>Nome da mãe:&ensp;</span>
                    {extrato[itemAtivo].motherName}
                  </NomeMae>
                  <RG>
                    <span>RG:&ensp;</span>{" "}
                    {extrato[itemAtivo].rg
                      ? extrato[itemAtivo].rg
                      : "NÃO CADASTRADO"}
                  </RG>
                </LinhaB>
                <LinhaC>
                  <Endereco>
                    <span>Endereço:&ensp;</span>
                    {extrato[itemAtivo].address.address}
                  </Endereco>
                  <Bairro>
                    <span>Bairro:&ensp;</span>
                    {extrato[itemAtivo].address.district}
                  </Bairro>
                  <Cidade>
                    <span>Cidade:&ensp;</span>
                    {extrato[itemAtivo].address.city}
                  </Cidade>
                </LinhaC>
              </ContainerID>

              <ContainerDB>
                <DadosBeneficio>Dados do Benefício</DadosBeneficio>
                <LinhaA>
                  <Especie>
                    <span>Espécie:&ensp;</span>{" "}
                    {extrato[itemAtivo].typeCode}
                  </Especie>
                </LinhaA>

                <LinhaBA>
                  <Situacao>
                    <span>Situação: &ensp;</span>{" "}
                    {extrato[itemAtivo].status}
                  </Situacao>
                </LinhaBA>

                <LinhaC>
                  <Caracter>
                    <span>Características: &ensp;</span>
                  </Caracter>
                </LinhaC>

                <LinhaD>
                  <RepLegal>
                    <span>Possui Representante Legal: &ensp;</span>{" "}
                    <p>
                      {extrato[itemAtivo].hasRepresentative
                        ? "SIM"
                        : "NÃO"}
                    </p>
                  </RepLegal>
                  <Pensao>
                    <span>É Pensão Alimentícia: &ensp;</span>{" "}
                    {extrato[itemAtivo].hasAlimony ? "SIM" : "NÃO"}
                  </Pensao>
                  <BloqEmprestimo>
                    <span>Bloqueado para Empréstimo: &ensp;</span>{" "}
                    {extrato[itemAtivo].isBrockedLoan ? "SIM" : "NÃO"}
                  </BloqEmprestimo>
                  <Elegivel>
                    <span>Elegível para empréstimo: &ensp;</span>
                    {extrato[itemAtivo].isElegibleLoan
                      ? "SIM"
                      : "NÃO"}
                  </Elegivel>
                </LinhaD>
              </ContainerDB>

              <ContainerMC>
                <MargemConsig>Margem Consignável</MargemConsig>
                <LinhaA>
                  <BaseCalc>
                    <span>Base de Cálculo: &ensp;</span>
                    R${extrato[itemAtivo].summary.baseValue}
                  </BaseCalc>
                  <MargemEmp>
                    <span>Margem para Empréstimo: &ensp;</span>{" "}
                    {extrato[itemAtivo].summary.availableLoanMargin}
                  </MargemEmp>
                  <MargemRMC>
                    <span>Margem para Cartão RMC: &ensp;</span>{" "}
                    {extrato[itemAtivo].summary.availableRmcMargin}{" "}
                  </MargemRMC>
                  <MargemIRMC>
                    <span>Margem para Cartão RMC: &ensp;</span>{" "}
                    {extrato[itemAtivo].summary.availableRccMargin}
                  </MargemIRMC>
                </LinhaA>
              </ContainerMC>

              <ContainerIP>
                <DadosBeneficio>Instituição Pagadora</DadosBeneficio>

                <LinhaA>
                  <BancoIP>
                    <span>CBC/Banco: &ensp;</span>{" "}
                    {extrato[itemAtivo].bankAccount.bankCode} -{" "}
                    {extrato[itemAtivo].bankAccount.bankName}
                  </BancoIP>
                  <Tipo>
                    <span>Tipo: &ensp;</span>
                    {extrato[itemAtivo].bankAccount.paymentMethodCode}
                  </Tipo>
                  <Ag>
                    <span>Ag.: &ensp;</span>{" "}
                    {extrato[itemAtivo].bankAccount.agency}
                  </Ag>
                  <Cc>
                    <span>C/C.: &ensp;</span>
                    {extrato[itemAtivo].bankAccount.number}
                  </Cc>
                </LinhaA>
              </ContainerIP>

              {/* Tabela */}
              <ContainerCE>
                <ContratoEmprestimos>
                  Contratos de Empréstimos
                </ContratoEmprestimos>

                <LinhaA>
                  <Ajuste>
                    <Emprestimo>N° Contrato</Emprestimo>
                    {extrato[itemAtivo].loanContracts.length ? (
                      extrato[itemAtivo].loanContracts
                        .sort((a, b) => {
                          return (
                            new Date(b.endorsementDate) - new Date(a.endorsementDate)
                          );
                        })
                        .map((contrato) => (
                          <>
                            <Ajuste key={contrato}>{contrato.number}</Ajuste>
                          </>
                        ))
                    ) : (
                      <></>
                    )}
                  </Ajuste>

                  <Ajuste>
                    <Banco>Banco</Banco>
                    {extrato[itemAtivo].loanContracts.length ? (
                      extrato[itemAtivo].loanContracts
                        .sort((a, b) => {
                          return (
                            new Date(b.endorsementDate) - new Date(a.endorsementDate)
                          );
                        })
                        .map((contrato) => (
                          <>
                            <Ajuste>
                              {contrato.bankCode} - {contrato.bankName}
                            </Ajuste>
                          </>
                        ))
                    ) : (
                      <></>
                    )}
                  </Ajuste>

                  <Ajuste>
                    <DataInclusao>Data inclusão</DataInclusao>
                    {extrato[itemAtivo].loanContracts.length ? (
                      extrato[itemAtivo].loanContracts
                        .sort((a, b) => {
                          return (
                            new Date(b.endorsementDate) - new Date(a.endorsementDate)
                          );
                        })
                        .map((contrato) => (
                          <>
                            <Ajuste>{contrato.endorsementDate}</Ajuste>
                          </>
                        ))
                    ) : (
                      <></>
                    )}
                  </Ajuste>

                  <Ajuste>
                    <ValorEmprestado>Vl Emprestado</ValorEmprestado>
                    {extrato[itemAtivo].loanContracts.length ? (
                      extrato[itemAtivo].loanContracts
                        .sort((a, b) => {
                          return (
                            new Date(b.endorsementDate) - new Date(a.endorsementDate)
                          );
                        })
                        .map((contrato) => (
                          <>
                            <Ajuste>{converter.format(contrato.loanValue)}</Ajuste>
                          </>
                        ))
                    ) : (
                      <></>
                    )}
                  </Ajuste>

                  <Ajuste>
                    <ValorParcela>Parcela</ValorParcela>
                    {extrato[itemAtivo].loanContracts.length ? (
                      extrato[itemAtivo].loanContracts
                        .sort((a, b) => {
                          return (
                            new Date(b.endorsementDate) - new Date(a.endorsementDate)
                          );
                        })
                        .map((contrato) => (
                          <>
                            <Ajuste>{converter.format(contrato.installmentValue)}</Ajuste>
                          </>
                        ))
                    ) : (
                      <></>
                    )}
                  </Ajuste>

                  <Ajuste>
                    <Parcelas>Prazo</Parcelas>
                    {extrato[itemAtivo].loanContracts.length ? (
                      extrato[itemAtivo].loanContracts
                        .sort((a, b) => {
                          return (
                            new Date(b.endorsementDate) - new Date(a.endorsementDate)
                          );
                        })
                        .map((contrato) => (
                          <>
                            <Ajuste>
                              {contrato.installments} - {contrato.installmentsPaid} Pagas
                            </Ajuste>
                          </>
                        ))
                    ) : (
                      <></>
                    )}
                  </Ajuste>

                  <Ajuste>
                    <Parcelas>Taxa</Parcelas>
                    {extrato[itemAtivo].loanContracts.length ? (
                      extrato[itemAtivo].loanContracts
                        .sort((a, b) => {
                          return (
                            new Date(b.endorsementDate) - new Date(a.endorsementDate)
                          );
                        })
                        .map((contrato) => (
                          <>
                            <Ajuste>
                              {contrato.rate.toFixed(2)}%
                            </Ajuste>
                          </>
                        ))
                    ) : (
                      <></>
                    )}
                  </Ajuste>

                  <Ajuste>
                    <Parcelas>Saldo Devedor</Parcelas>
                    {extrato[itemAtivo].loanContracts.length ? (
                      extrato[itemAtivo].loanContracts
                        .sort((a, b) => {
                          return (
                            new Date(b.endorsementDate) - new Date(a.endorsementDate)
                          );
                        })
                        .map((contrato) => (
                          <>
                            <Ajuste>
                              {converter.format(contrato.baseValue)}
                            </Ajuste>
                          </>
                        ))
                    ) : (
                      <></>
                    )}
                  </Ajuste>
                </LinhaA>
              </ContainerCE>

              <ContainerRMC>
                <TitleRMC>Contratos de cartão RMC</TitleRMC>
                <Center>
                  <NumContrato>
                    <span>Nº Contrato: &ensp;</span>{" "}
                    {extrato[itemAtivo].cardContracts.length ? (
                      extrato[itemAtivo].cardContracts.map((contrato) => (
                        <p key={contrato.number}>{contrato.number}</p>
                      ))
                    ) : (
                      <></>
                    )}
                  </NumContrato>
                  <BancoRCC>
                    <span>CBC/Banco: &ensp;</span>{" "}
                    {extrato[itemAtivo].cardContracts.length ? (
                      extrato[itemAtivo].cardContracts.map((contrato) => (
                        <p key={contrato.contrato}>
                          {" "}
                          {contrato.bankCode} -{contrato.bankName}
                        </p>
                      ))
                    ) : (
                      <></>
                    )}
                  </BancoRCC>
                  <DataInclusaoRMC>
                    <span>Data inclusão: &ensp;</span>{" "}
                    {extrato[itemAtivo].cardContracts.length ? (
                      extrato[itemAtivo].cardContracts.map((contrato) => (
                        <p key={contrato.contrato}>
                          {" "}
                          {contrato.endorsementDate
                            ? contrato.endorsementDate
                            : "Não encontrado"}
                        </p>
                      ))
                    ) : (
                      <></>
                    )}
                  </DataInclusaoRMC>
                  <SituacaoRCC>
                    <span>Situação: &ensp;</span>
                    {extrato[itemAtivo].cardContracts.length ? (
                      extrato[itemAtivo].cardContracts.map((contrato) => (
                        <p key={contrato.contrato}>{contrato.status}</p>
                      ))
                    ) : (
                      <></>
                    )}
                  </SituacaoRCC>
                  <LimitRMC>
                    <span>LimiteCartão: &ensp;</span>{" "}
                    {extrato[itemAtivo].cardContracts.length ? (
                      extrato[itemAtivo].cardContracts.map((contrato) => (
                        <p key={contrato.contrato}>{contrato.limitValue}</p>
                      ))
                    ) : (
                      <></>
                    )}
                  </LimitRMC>
                </Center>
              </ContainerRMC>
            </ContainerFullExtrato>
          </>
        ) : (
          <></>
        )}
      </section>
    </>
  );
}
