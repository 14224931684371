// js/formulas.js

import * as formulajs from "@formulajs/formulajs";

// Função para calcular a taxa
export const calcularTaxa = (nper, pmt, pv) => {
  const taxa = formulajs.RATE(nper, pmt, pv);
  return typeof taxa === "number" && !isNaN(taxa) ? taxa : null;
};

// Função para calcular a simulação com a fórmula 1.005 * PMT(taxaSimulation, prazo, -saldoDevedor)
export const calcularSimulacao = (taxaSimulacao, prazo, saldoDevedor) => {
  const taxaMensal = taxaSimulacao / 100;
  const saldoNegativo = -Math.abs(saldoDevedor);
  const resultado = 1.005 * formulajs.PMT(taxaMensal, prazo, saldoNegativo);
  return typeof resultado === "number" && !isNaN(resultado) ? resultado : null;
};

// Função para calcular o Valor Presente (VP)
export const calcularValorPresente = (taxaSimulacao, prazo, parcela) => {
  const taxaMensal = taxaSimulacao / 100;
  const parcelaNegativa = -Math.abs(parcela);
  const vp = formulajs.PV(taxaMensal, 84, parcelaNegativa);
  return typeof vp === "number" && !isNaN(vp) ? vp : null;
};

// Função para calcular o Valor Presente (VP) com prazo fixo de 84
export const calcularValorPresenteFixo = (taxaSimulacao, parcela) => {
  const taxaMensal = taxaSimulacao / 100;
  const parcelaNegativa = -Math.abs(parcela);
  const vpFixo = formulajs.PV(taxaMensal, 84, parcelaNegativa);
  return typeof vpFixo === "number" && !isNaN(vpFixo) ? vpFixo : null;
};

// Função para calcular o Valor Operação
export const calcularValorOperacao = (coeficiente, parcela) => {
  const coeficienteMensal = coeficiente; // Converte o coeficiente para porcentagem decimal
  const parcelaNegativa = -Math.abs(parcela); // Parcela deve ser negativa
  const valorOperacao = formulajs.PV(coeficienteMensal, 84, parcelaNegativa); // Chama a função PV
  return typeof valorOperacao === "number" && !isNaN(valorOperacao) ? valorOperacao : null;
};

