import "./style.css";
import React, { useState, useEffect } from "react";
import { fetchBancos } from "../JS/fetchBancos";
import { fetchTabelas } from "../JS/fetchTabelas";
import { simulateLoan } from "../JS/simulateLoan.js";
import ModalIncluirAF from "../ModalIncluirAF";
import ModalManual from "../ModalManual";
import ModalSimulation from "../ModalSimulation/";
import ModalDoc from "../ModalDoc/";
import ModalVend from "../ModalComplemento/";
import ModalComplemento from "../ModalComplemento/";
import tiposDeConta from "../Dados Estaticos/tiposConta.js";
import mapeamentoSexo from "../Dados Estaticos/sexosID.js";
import camposFixos from "../Dados Estaticos/camposFixos.js";
const bancos = require("../JS/bancosAgille.json");

const obterValorSexo = (sexo) => {
  return mapeamentoSexo.hasOwnProperty(sexo) ? mapeamentoSexo[sexo] : null;
};

const obterInformacoesObrigatorias = (
  dados,
  valorSexo,
  codigoBancoObg,
  tipoConta
) => {
  return {
    nome: dados.name,
    cpf: dados.documentNumber,
    beneficio: dados.benefitNumber,
    especie: dados.typeCode,
    mae: dados.motherName,
    sexo: valorSexo,
    dtNascimento: new Date(dados.birthDate),
    endereco: dados.address.address,
    bairro: dados.address.district,
    cidade: dados.address.city,
    estado: dados.address.state,
    cep: dados.address.zipCode,
    agencia: dados.bankAccount.agency,
    conta: dados.bankAccount.number,
    codigoBancoObg,
    tipoConta,
    nomeBancoConta: dados.bankAccount.bankName,
    estadoBeneficio: dados.address.state,
    valorBruto: dados.summary.benefitAmount,
    salario: dados.summary.baseValue,
  };
};

const filtrarContratos = (contrato, filtroBanco, filtroSituacao) => {
  if (
    filtroBanco &&
    contrato.bankName.toLowerCase().indexOf(filtroBanco.toLowerCase()) === -1
  ) {
    return false;
  }
  if (
    filtroSituacao &&
    contrato.status.toLowerCase().indexOf(filtroSituacao.toLowerCase()) === -1
  ) {
    return false;
  }
  return true;
};

const ordenarContratos = (contratos, ordenacao) => {
  switch (ordenacao) {
    case "parcela":
      return [...contratos].sort(
        (a, b) => a.installmentValue - b.installmentValue
      );
    case "saldo":
      return [...contratos].sort((a, b) => a.loanValue - b.loanValue);
    case "taxa":
      return [...contratos].sort((a, b) => a.rate - b.rate);
    default:
      return contratos;
  }
};

export default function TablePromoSys({ dados }) {
  const [tabelas, setTabelas] = useState([]);
  const [filtroBanco, setFiltroBanco] = useState("");
  const [filtroSituacao, setFiltroSituacao] = useState("");
  const [ordenacao, setOrdenacao] = useState(null);
  const [checkedItems, setCheckedItems] = useState(new Map());
  const [mapaBancos, setMapaBancos] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContrato, setSelectedContrato] = useState(null);
  const [isModalManualOpen, setIsModalManualOpen] = useState(false);
  const [isModalSimulationOpen, setModalSimulationOpen] = useState(false);
  const [documentoInfo, setDocumentoInfo] = useState(null);
  const [isModalDocOpen, setIsModalDocOpen] = useState(false);
  const [resultado, setResultado] = useState({
    informacoesobrigatorias: {},
    contratosSelecionados: [],
    camposFixos: {},
  });
  const [tabelasSelecionadas, setTabelasSelecionadas] = useState(new Map());
  const [isModalComplementoOpen, setIsModalComplementoOpen] = useState(false);

  // Estado para o contrato manual
  const [manualContract, setManualContract] = useState({
    number: "",
    bankCode: "",
    bankName: "",
    installmentValue: "",
    loanValue: "",
    installments: "",
    remainingInstallments: "",
    rate: "",
  });

  const handleOpenModalDoc = (info) => {
    setDocumentoInfo(info);
    setIsModalDocOpen(true);
  };

  const handleConfirmSimulation = async (resultadoAtualizado) => {
    const contratos = resultadoAtualizado.contratosSelecionados;
    if (!contratos || contratos.length === 0) {
      console.error("Nenhum contrato selecionado.");
      return;
    }

    const contratoSelecionado = contratos[0];

    if (!contratoSelecionado) {
      console.error("Contrato selecionado está indefinido.");
      return;
    }

    const tabelaSelecionada = tabelasSelecionadas.get(
      contratoSelecionado.numeroContrato
    );

    if (!tabelaSelecionada) {
      console.error("Nenhuma tabela selecionada.");
      alert("Por favor, selecione uma tabela antes de confirmar.");
      return;
    }

    const loanData = {
      productCode: tabelaSelecionada.productCode,
      originalContract: {
        lenderCode: contratoSelecionado.bankCode || "",
        number: contratoSelecionado.numeroContrato || "",
        installmentValue: contratoSelecionado.valorParcela || 0,
        installmentsRemaining: contratoSelecionado.prazoRestante || 0,
        loanBalanceValue: contratoSelecionado.saldo || 0,
      },
    };

    console.log("Enviando loanData para simulação:", loanData);

    try {
      const response = await simulateLoan(loanData);
      console.log("Resposta da simulação:", response);

      console.log("Simulação realizada com sucesso. Prosseguindo...");

      setModalSimulationOpen(false);
      setIsModalDocOpen(true);
    } catch (error) {
      console.error("Erro ao simular o empréstimo:", error);
      alert("Erro ao simular o empréstimo. Por favor, tente novamente.");
    }
  };

  const handleSelectTabela = (numeroContrato, tabelaSelecionada) => {
    setTabelasSelecionadas((prevTabelas) => {
      const newTabelas = new Map(prevTabelas);
      newTabelas.set(numeroContrato, {
        productCode: tabelaSelecionada.productCode,
        idAgille: tabelaSelecionada.idAgille,
      });
      return newTabelas;
    });
  };

  const handleComplementoConfirm = (complementoData) => {
    setResultado((prevResultado) => ({
      ...prevResultado,
      informacoesobrigatorias: {
        ...prevResultado.informacoesobrigatorias,
        ddd: complementoData.ddd,
        telefone: complementoData.telefone,
      },
    }));
    setIsModalComplementoOpen(false);
  };

  const handleCloseModalManual = () => setIsModalManualOpen(false);
  const handleOpenModalComplemento = () => {
    setIsModalComplementoOpen(true);
  };

  const handleConfirmVendedor = (vendedorData) => {
    setResultado((prevResultado) => ({
      ...prevResultado,
      informacoesobrigatorias: {
        ...prevResultado.informacoesobrigatorias,
        vendedorId: vendedorData.vendedorId,
      },
    }));

    handleOpenModalComplemento();
  };

  useEffect(() => {
    const buscarBancosETabelas = async () => {
      const [bancosData, tabelasData] = await Promise.all([
        fetchBancos(),
        fetchTabelas(),
      ]);
      const mapa = bancosData.reduce((acc, banco) => {
        acc[banco.CodigoBanco] = banco.Id;
        return acc;
      }, {});
      setMapaBancos(mapa);
      setTabelas(tabelasData);
    };
    console.log(resultado);

    buscarBancosETabelas();
  }, [resultado]);

  const handleCheckboxChange = (contratoNumber) => {
    setCheckedItems((prevItems) => {
      const updatedItems = new Map(prevItems);
      const isChecked = updatedItems.get(contratoNumber) || false;
      updatedItems.set(contratoNumber, !isChecked);

      if (!isChecked) {
        setSelectedContrato(contratoNumber);
        setModalSimulationOpen(true);
      } else {
        setSelectedContrato(null);
        setModalSimulationOpen(false);
      }

      return updatedItems;
    });
  };

  const handleManualSimulate = () => {
    // Verificar se todos os campos estão preenchidos
    if (
      !manualContract.number ||
      !manualContract.bankCode ||
      !manualContract.installmentValue ||
      !manualContract.loanValue ||
      !manualContract.installments ||
      !manualContract.remainingInstallments ||
      !manualContract.rate
    ) {
      alert("Por favor, preencha todos os campos do contrato manual.");
      return;
    }

    setCheckedItems((prevItems) => {
      const updatedItems = new Map(prevItems);
      const isChecked = updatedItems.get("manual") || false;
      updatedItems.set("manual", !isChecked);

      if (!isChecked) {
        // Se não estava selecionado, seleciona e abre o modal
        setSelectedContrato("manual");
        setModalSimulationOpen(true);
      } else {
        // Se já estava selecionado, desmarca e fecha o modal
        setSelectedContrato(null);
        setModalSimulationOpen(false);
      }

      return updatedItems;
    });
  };

  const handleSalvarSelecionados = async () => {
    const valorSexo = obterValorSexo(dados.sex);
    let bancoObrigatorio = false;

    if (dados.bankAccount?.bankCode) {
      bancoObrigatorio = bancos.find(
        (banco) => banco.CodigoBanco === dados.bankAccount.bankCode
      );
    }

    const codigoBancoObg = bancoObrigatorio ? bancoObrigatorio.Id : null;
    const tipoConta =
      tiposDeConta[dados.paymentMethodName] !== undefined
        ? tiposDeConta[dados.paymentMethodName]
        : 0;

    const informacoesobrigatorias = obterInformacoesObrigatorias(
      dados,
      valorSexo,
      codigoBancoObg,
      tipoConta
    );

    const contratosSelecionados = Array.from(checkedItems)
      .filter(([key, value]) => value)
      .map(([key]) => {
        let contrato;

        if (key === "manual") {
          contrato = {
            number: manualContract.number,
            bankName: manualContract.bankName,
            bankCode: manualContract.bankCode,
            installmentValue: parseFloat(manualContract.installmentValue),
            loanValue: parseFloat(manualContract.loanValue),
            installments: parseInt(manualContract.installments),
            remainingInstallments: parseInt(manualContract.remainingInstallments),
            rate: parseFloat(manualContract.rate),
          };
        } else {
          contrato = dados.loanContracts.find((c) => c.number === key);
        }

        if (!contrato) {
          console.error(`Contrato não encontrado para o número ${key}`);
          return null;
        }

        const bancoContrato = bancos.find(
          (banco) => banco.CodigoBanco === contrato.bankCode
        );
        const codigoBank = bancoContrato ? bancoContrato.Id : null;
        const tabelaSelecionada = tabelasSelecionadas.get(contrato.number);

        if (!tabelaSelecionada) {
          console.warn(
            `Tabela não selecionada para o contrato ${contrato.number}`
          );
        }

        return {
          numeroContrato: contrato.number,
          bancoContrato: contrato.bankName,
          dataContrato: dados.beginDate,
          orgaoExpedidor: dados.paymentState,
          valorParcela: contrato.installmentValue.toFixed(2),
          saldo: contrato.loanValue.toFixed(2),
          prazoRestante: contrato.remainingInstallments,
          prazo: contrato.installments,
          taxa: contrato.rate.toFixed(2) + "%",
          codigoBank,
          bankCode: contrato.bankCode,
          tabelaSelecionada,
        };
      })
      .filter(Boolean);

    const novoResultado = {
      informacoesobrigatorias,
      contratosSelecionados,
      camposFixos,
    };

    console.log("Novo resultado:", novoResultado);
    setResultado(novoResultado);

    return novoResultado;
  };

  const contratosFiltrados = dados.loanContracts.filter((contrato) =>
    filtrarContratos(contrato, filtroBanco, filtroSituacao)
  );
  const contratosOrdenados = ordenarContratos(contratosFiltrados, ordenacao);

  return (
    <>
      <table className="table-promosys">
        <thead className="thead-promosys">
          <tr className="tr-promosys">
            <th className="th-promosys">Contrato</th>
            <th className="th-promosys">Banco</th>
            <th className="th-promosys">Valor Parcela</th>
            <th className="th-promosys">Saldo</th>
            <th className="th-promosys">Prazo</th>
            <th className="th-promosys">Taxa</th>
            <th className="th-promosys">Simulação</th>
          </tr>
        </thead>
        <tbody className="tbody-promosys">
          {contratosOrdenados.map((contrato, index) => (
            <tr key={index}>
              <td className="td-promosys">{contrato.number}</td>
              <td className="td-promosys">{contrato.bankName}</td>
              <td className="td-promosys">
                R$ {contrato.installmentValue.toFixed(2)}
              </td>
              <td className="td-promosys">
                R$ {contrato.loanValue.toFixed(2)}
              </td>
              <td className="td-promosys">
                {contrato.remainingInstallments}/{contrato.installments}
              </td>
              <td className="td-promosys">{contrato.rate.toFixed(2)}%</td>
              <td className="td-promosys">
                <button
                  className={`button-toggle ${
                    checkedItems.get(contrato.number) ? "active" : ""
                  }`}
                  onClick={() => handleCheckboxChange(contrato.number)}
                >
                  {checkedItems.get(contrato.number) ? "Cancelar" : "Simular"}
                </button>
              </td>
            </tr>
          ))}
          {/* Linha para entrada manual */}
          <tr>
            <td className="td-promosys">
              <input
                type="text"
                placeholder="Contrato"
                value={manualContract.number}
                onChange={(e) =>
                  setManualContract({ ...manualContract, number: e.target.value })
                }
              />
            </td>
            <td className="td-promosys">
              <select
                value={manualContract.bankCode}
                onChange={(e) => {
                  const selectedBankCode = e.target.value;
                  const selectedBank = bancos.find(
                    (banco) =>
                      banco.CodigoBanco != null &&
                      banco.CodigoBanco.toString() === selectedBankCode
                  );
                  if (selectedBank) {
                    setManualContract({
                      ...manualContract,
                      bankName: selectedBank.Nome,
                      bankCode: selectedBank.CodigoBanco.toString(),
                    });
                  }
                }}
              >
                <option value="">Selecione um banco</option>
                {bancos
                  .filter((banco) => banco.CodigoBanco != null)
                  .map((banco) => (
                    <option key={banco.Id} value={banco.CodigoBanco}>
                      {banco.Nome}
                    </option>
                  ))}
              </select>
            </td>
            <td className="td-promosys">
              <input
                type="number"
                placeholder="Valor Parcela"
                value={manualContract.installmentValue}
                onChange={(e) =>
                  setManualContract({
                    ...manualContract,
                    installmentValue: e.target.value,
                  })
                }
              />
            </td>
            <td className="td-promosys">
              <input
                type="number"
                placeholder="Saldo"
                value={manualContract.loanValue}
                onChange={(e) =>
                  setManualContract({ ...manualContract, loanValue: e.target.value })
                }
              />
            </td>
            <td className="td-promosys">
              <input
                type="text"
                placeholder="Prazo (Restante/Total)"
                value={`${manualContract.remainingInstallments}/${manualContract.installments}`}
                onChange={(e) => {
                  const [remaining, total] = e.target.value.split("/");
                  setManualContract({
                    ...manualContract,
                    remainingInstallments: remaining,
                    installments: total,
                  });
                }}
              />
            </td>
            <td className="td-promosys">
              <input
                type="number"
                placeholder="Taxa (%)"
                value={manualContract.rate}
                onChange={(e) =>
                  setManualContract({ ...manualContract, rate: e.target.value })
                }
              />
            </td>
            <td className="td-promosys">
              <button
                className={`button-toggle ${
                  checkedItems.get("manual") ? "active" : ""
                }`}
                onClick={handleManualSimulate}
              >
                {checkedItems.get("manual") ? "Cancelar" : "Simular"}
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <ModalIncluirAF
        data={resultado}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onModalSubmit={() => setIsModalOpen(false)}
      />
      <ModalManual
        data={resultado}
        isOpen={isModalManualOpen}
        onClose={handleCloseModalManual}
      />

      {selectedContrato && (
        <ModalSimulation
          data={resultado}
          isOpen={isModalSimulationOpen}
          onClose={() => setModalSimulationOpen(false)}
          onConfirm={handleConfirmSimulation}
          handleSalvarSelecionado={handleSalvarSelecionados}
          contratoNumber={
            selectedContrato === "manual"
              ? manualContract.number
              : selectedContrato
          }
          tabelas={tabelas}
          handleSelectTabela={handleSelectTabela}
        />
      )}

      <ModalDoc
        data={resultado}
        isOpen={isModalDocOpen}
        onClose={() => setIsModalDocOpen(false)}
        onConfirm={() => setIsModalDocOpen(false)}
        documentoInfo={documentoInfo}
        setResultado={setResultado}
      />

      {isModalOpen && (
        <ModalVend
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleSalvarSelecionados}
          setResultado={setResultado}
          resultado={resultado}
        />
      )}

      {isModalComplementoOpen && (
        <ModalComplemento
          isOpen={isModalComplementoOpen}
          onClose={() => setIsModalComplementoOpen(false)}
          onConfirm={handleComplementoConfirm}
          setResultado={setResultado}
          resultado={resultado}
        />
      )}
    </>
  );
}
