import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { connectToApi } from "./DinamicAPI";
export const axiosInstance = axios.create({});

const urlAPI = "https://portal.agillecred.com.br/api/"

if(urlAPI == null){
  connectToApi()
} else{
  axiosInstance.defaults.baseURL = urlAPI;
}

const refreshAuthLogic = (
  failedRequest,
  config = {
    method: "get",
    url: "refresh",
    headers: {
      cookies: `${JSON.parse(localStorage.getItem("user"))[8]}`,
    },
  }
) =>
  axiosInstance(config).then((response) => {
    var local = JSON.parse(localStorage.getItem("user"));
    local[0] = response.data.accessToken;
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(local));
    failedRequest.response.config.headers["Authorization"] =
      "Bearer " + response.data.accessToken;
    return Promise.resolve();
  });

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
  statusCodes: [403],
});

const notAuthorizedLogic = (failedRequest) => {
  if(failedRequest.response.config.url != "login"){
    localStorage.removeItem("user");
  }
}

createAuthRefreshInterceptor(axiosInstance, notAuthorizedLogic, {
  statusCodes: [401],
});

export const agilusAPI = axios.create({
  baseURL: 'https://agiluscrm.com.br:40000/api/',
  headers: {
    'Authorization': `Bearer A6pWwCwciYOXbR7Se8DtIn3ezAv_Mzh68EtPU3Ib5tnP6UuOt6ZXK2Vf-V-gHsRKgvzR-yGXQsWuAs4UUnzOnruDuLhntqWfHAUXyA6r11j9ztgiYN3l4O9j3IDnH6ByMGbqOftSPzYGutF8ggAYkORDhrd8RmRDwnadqoG7gcpe1qz5Zhva_K-jzXKcIygkxwLC5aH3isA8Xtl6o_70EHSK5KPN6dUgoUrNf8Vt-AuRiuIv4lYjfBVe_wOwvxnoMbGNrp7vOARFCL2ANYeaSikFc2bTMmSSreDiYvvxiK7NObzYN5TcnwW-rcUe2GOarYztTkzaACHkUs_ssfPACeYjnI2UR1iF8XoofNi5b_1_Pukzx3D6pqROBSsxGWVg5RWoe-c7LZS4NVj946Huk-hUPoxdA56R7jJXRH_ia2lGTLGzu63TcsWY-qxs6syX`,
  }
})
