import React, { forwardRef } from "react";
import "./PortRefin.css";

const PortRefin = forwardRef(({
  dataInclusao,
  bancoOriginador,
  bancoDestino,
  valorParcela,
  valorTroco,
}, ref) => {
  return (
    <div className="port-refin-container" ref={ref}>
      <div className="section">
        <div className="item text-parcela-atual">
          <strong>Valor Parcela</strong>
          <p>{valorParcela}</p>
        </div>
        <div className="item text-valor-troco">
          <strong>Valor Troco</strong>
          <p>{valorTroco}</p>
        </div>
      </div>
    </div>
  );
});

export default PortRefin;
