import "./Global.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./assets/pages/Login/Login";
import Home from "./assets/pages/Home/index";
import DigitacaoCrefisa from "./assets/Robos/Digitação/Crefisa/crefisa";
import DigitacaoPagBank from "./assets/Robos/Digitação/Pagbank/pagbank";
import DigitacaoFacta from "./assets/Robos/Digitação/Facta/BancoFacta";
import DigitacaoFacta2 from "./assets/Robos/Digitação/Facta2/Facta";
import NaoEncontrado from "./assets/pages/Erro/NaoEncontrado";
import RequireAuth from "./Components/RequireAuth";
import Layout from "./Components/LayoutComponente/Layout";
import ConsultaFacta from "./assets/Consultas/Facta/ConsultaFacta";
import FGTSFacta from './assets/Consultas/FGTS/Facta'
import ConsultaCrefaz from "./assets/Consultas/Crefaz";
import ConsultaPromoSys from './assets/Consultas/PromoSyS'
import NaoAutorizado from "./assets/pages/Erro/NaoAutorizado";
import Perfil from "./assets/pages/Perfil/Perfil";
import Post from "./assets/pages/postagens";
import AtualizarFrase from "./assets/pages/Frase/AtualizarFrase";
import AtualizarUsuarios from "./assets/pages/Usuarios/AtualizarUsers";
import { roles } from "./api/roles";
import ConsultaExt from "./assets/pages/ConsultaExt";
import LoginParceiro from "./assets/pages/LoginsParceiros";
import Roteiros from "./assets/pages/Roteiros";
import GProposta from "./assets/Consultas/GProposta";

const allLogins = [
  roles.Admin,
  roles.Coordenador,
  roles.Operacional,
  roles.Operador,
  roles.Supervisor,
  roles.Recursos_Humanos,
  roles.CEO,
];

const adminCeo = [roles.Admin, roles.CEO];

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>

        {/* Públicas */}
        <Route path="/" element={<Login />} />
        <Route path="consulta/extrato/:cpf" element={<ConsultaExt />} />

        {/* Todos */}
        <Route
          element={
            <RequireAuth
              allowedRoles={allLogins}
            />
          }
        > 
          <Route path="home" element={<Home />} />
          <Route path="perfil" element={<Perfil />} />

          <Route path="consulta">
            <Route path="facta" element={<ConsultaFacta />} />
            <Route path="facta/:cpf" element={<ConsultaFacta />} />
            <Route path="extrato" element={<ConsultaExt />} />
            <Route path="fgts/facta" element={<FGTSFacta />} />
            <Route path="incluirAF" element={<ConsultaPromoSys />} />
            <Route path="roteiro" element={<Roteiros />} />
            <Route path="gproposta" element={<GProposta /> } />
          </Route>
        </Route>

        <Route
          element={
            <RequireAuth
              allowedRoles={adminCeo}
            />
          }
        >
          <Route path="consulta/crefaz" element={<ConsultaCrefaz />} />
        </Route>

        {/* Digitação */}
        <Route
          element={
            <RequireAuth
              allowedRoles={adminCeo.concat([
                roles.Operacional,
              ])}
            />
          }
        >
          <Route path="logins-parceiros" element={<LoginParceiro />} />
          <Route path="facta" element={<DigitacaoFacta />} />
        </Route>

        {/* RH */}
        <Route
          element={
            <RequireAuth allowedRoles={adminCeo.concat([roles.Recursos_Humanos])} />
          }
        >
          <Route path="post" element={<Post />} />
          <Route path="frase" element={<AtualizarFrase />} />
        </Route>

        <Route
          element={
            <RequireAuth allowedRoles={[roles.CEO]} />
          }
        >
          {/* //<Route path="faturas" element={<PaginaFatura />} /> */}
        </Route>

        {/* 404 || 403*/}
        <Route path="*" element={<NaoEncontrado />} />
        <Route path="naoAutorizado" element={<NaoAutorizado />} />

        {/* Admin || Desenvolvimento ou Protótipos */}
        <Route element={<RequireAuth allowedRoles={adminCeo} />}>
          <Route path="usuarios" element={<AtualizarUsuarios />} />
          <Route path="crefisa" element={<DigitacaoCrefisa />} />
          <Route path="pagbank" element={<DigitacaoPagBank />} />
          <Route path="facta2" element={<DigitacaoFacta2 />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
